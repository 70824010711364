import React, { CSSProperties } from "react";
import { observer } from "mobx-react-lite";
import { XRichEdit } from "@external-types/widgets/types";
import { Widget } from "@components/widgets/widget";

interface Props {
	widget: XRichEdit;
}

async function contentLoaded(container: HTMLElement) {
	const images = container.querySelectorAll("img");

	if (images) {
		const promises = Array.from(images).map(img => {
			if (!img.complete) {
				return new Promise(resolve => {
					img.addEventListener("load", () => {
						resolve(true);
					});
				});
			}
			return Promise.resolve(true);
		});

		await Promise.all(promises);
	}
}

export const RichEditWidget = observer(function({ widget }: Props) {
	const ref = React.useRef<HTMLDivElement>(null);
	const containerRef = React.useRef<HTMLDivElement>(null);
	const [width, setWidth] = React.useState<number | undefined>();
	const [height, setHeight] = React.useState<number | "auto">("auto");
	const [html, setHtml] = React.useState("");
	const [style, setStyle] = React.useState<CSSProperties>();

	React.useEffect(() => {
		const { width } = ref.current!.getBoundingClientRect();

		setWidth(width);
		setHtml(widget.html);

		setTimeout(async () => {
			await contentLoaded(containerRef.current!);
			scaleContent(width);
		});
	}, []);

	function scaleContent(contentWidth: number) {
		const { scrollWidth, scrollHeight } = containerRef.current!;
		const scale = contentWidth / scrollWidth;

		if (scale < 1) {
			setHeight(scrollHeight * scale);
			setStyle({
				transform: `scale(${scale})`,
				transformOrigin: "top left",
				position: "absolute",
				top: 0,
				left: 0,
			});
		}
	}

	return (
		<Widget widget={widget} caption={widget.caption} isRequired={false}>
			<div ref={ref} className="widget-richedit" style={{ width, height, position: "relative" }}>
				<div ref={containerRef} dangerouslySetInnerHTML={{ __html: html }} style={style} />
			</div>
		</Widget>
	);
});
