import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import cn from "classnames";
import { StoreContext } from "../context";
import { TextId } from "@helpers/language";

export const Login = observer(function () {
	const [username, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const { authStore, locale } = useContext(StoreContext);

	function handleUsernameChange(e: React.FormEvent<HTMLInputElement>) {
		setUserName(e.currentTarget.value);
	}

	function handlePasswordChange(e: React.FormEvent<HTMLInputElement>) {
		setPassword(e.currentTarget.value);
	}

	function handleLogin() {
		authStore.login({
			username,
			password,
		});
	}

	function handleSubmit(e: React.FormEvent) {
		e.preventDefault();
	}

	const hasError = authStore.errorMessage != null;

	return (
		<div className="login-page">
			<form className={cn("login-form", { "login-form--error": hasError })} onSubmit={handleSubmit}>
				{hasError && <div className="form__error-message">{authStore.errorMessage}</div>}

				<div className="form__group">
					<div className="form__row">
						<label className="form__label">{locale.text(TextId.T_112)}</label>
						<input
							className="form__input"
							type="text"
							onChange={handleUsernameChange}
							placeholder={locale.text(TextId.T_102)}
						/>
					</div>
					<div className="form__row">
						<label className="form__label">{locale.text(TextId.T_120)}</label>
						<input
							className="form__input"
							type="password"
							onChange={handlePasswordChange}
							placeholder={locale.text(TextId.T_103)}
						/>
					</div>
				</div>
				<div className="form__group">
					<button onClick={handleLogin} type="submit" className="button button--primary">
						{locale.text(TextId.T_106)}
					</button>
				</div>
			</form>
		</div>
	);
});
