import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import TextareaAutosize from "react-textarea-autosize";
import { XTextArea } from "@external-types/widgets/types";
import { QrcodeIcon } from "@icons/index";
import { StoreContext } from "../../context";
import { Widget } from "@components/widgets/widget";
import { Html5Qrcode, Html5QrcodeScannerState } from "html5-qrcode";
import axios from "axios";
import { TextId } from "@helpers/language";

interface QrCodeScannerProps {
	onCancel: () => void;
	onScanned: (text: string) => void;
}

function QrCodeScanner({ onCancel, onScanned }: QrCodeScannerProps) {
	const [error, setError] = useState("");
	const { locale } = useContext(StoreContext);

	useEffect(() => {
		const html5QrCode = new Html5Qrcode("reader");
		const config = {
			fps: 10,
			qrbox: { width: 150, height: 150 },
		};

		html5QrCode.start({ facingMode: "environment" }, config, onScanned, undefined).catch((err) => {
			setError(err);
		});

		return () => {
			const state = html5QrCode.getState();

			if (state === Html5QrcodeScannerState.SCANNING || state === Html5QrcodeScannerState.PAUSED) {
				html5QrCode.stop();
			}
		};
	}, [onScanned]);

	return (
		<div className="qr-code-scanner-panel">
			<div id="reader"></div>
			{error && (
				<div className="qr-code-scanner-panel__error">
					{locale.text(TextId.T_119)}: {error}
				</div>
			)}
			<div className="qr-code-scanner-panel__actions">
				<button type="submit" className="button button--primary button--submit" onClick={onCancel}>
					{locale.text(TextId.T_117)}
				</button>
			</div>
		</div>
	);
}

interface Props {
	widget: XTextArea;
}

export const TextAreaWidget = observer(function ({ widget }: Props) {
	const { tasksStore } = useContext(StoreContext);
	const answer = tasksStore.task!.getFormValue(widget.id);
	const value = answer ? answer.value : "";
	const [isScanning, setIsScanning] = useState<boolean>(false);
	const isScanInProgress = React.useRef(false);

	function handleStartScan() {
		setIsScanning(true);
	}

	function handleStop() {
		setIsScanning(false);
	}

	async function handleScanned(value: string) {
		if (!isScanInProgress.current) {
			try {
				isScanInProgress.current = true;

				const { data } = await axios.post("/api/proxy/disai/find", value);

				if (data !== "Not found") {
					value = `${data} ${value}`;
				}
			} finally {
				updateAnswer(value);
				setIsScanning(false);
				isScanInProgress.current = false;
			}
		}
	}

	function updateAnswer(value: string) {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value,
			type: "text",
		});
	}

	function handleChange(e: React.FormEvent<HTMLTextAreaElement>) {
		updateAnswer(e.currentTarget.value);
	}

	const caption = (
		<div className="widget-textarea-caption">
			{widget.caption}
			<div className="scan-button" onClick={handleStartScan}>
				<QrcodeIcon className="scan-button__icon" />
			</div>
		</div>
	);

	return (
		<>
			{isScanning && <QrCodeScanner onCancel={handleStop} onScanned={handleScanned} />}
			<Widget widget={widget} caption={caption} isRequired={widget.is_required}>
				<div className="widget-textarea">
					<TextareaAutosize className="widget-textarea__control" onChange={handleChange} value={value} />
				</div>
			</Widget>
		</>
	);
});
