import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { XPhoto } from "@external-types/widgets/types";
import { AttachmentIcon, AudioIcon, PhotoIcon, VideoIcon } from "@icons/index";
import { StoreContext } from "../../context";
import { Widget } from "@components/widgets/widget";
import { MediaFile } from "../../stores/models/open-link-task";
import { TextId } from "@helpers/language";

interface Props {
	widget: XPhoto;
}

const imgFileTypes = new Set(["image/png", "image/jpeg", "image/gif"]);

export const PhotoWidget = observer(function ({ widget }: Props) {
	const { tasksStore, locale } = useContext(StoreContext);

	const mediaFiles = tasksStore.task!.media.get(widget.id) ?? [];

	function handleChange(e: React.FormEvent<HTMLInputElement>) {
		const files = e.currentTarget.files;

		if (files && files.length > 0) {
			const file = files[0];

			tasksStore.task!.preloadMedia(widget.id, file);

			e.currentTarget.value = "";
		}
	}

	function handleRemove(mediaId: string) {
		return () => {
			tasksStore.task!.removeMedia(widget.id, mediaId);
		};
	}

	return (
		<Widget widget={widget} caption={widget.caption} isRequired={widget.is_required}>
			<div className="widget-media__buttons">
				<label className="widget-media__button">
					<input accept="image/*" capture="camera" type="file" onChange={handleChange} />
					<PhotoIcon className="widget-media__button-icon" />
					{locale.text(TextId.T_126)}
				</label>
				<label className="widget-media__button">
					<input accept="video/*" capture="camera" type="file" onChange={handleChange} />
					<VideoIcon className="widget-media__button-icon" />
					{locale.text(TextId.T_105)}
				</label>
				<label className="widget-media__button">
					<input accept="audio/*" capture="camera" type="file" onChange={handleChange} />
					<AudioIcon className="widget-media__button-icon" />
					{locale.text(TextId.T_101)}
				</label>
				<label className="widget-media__button">
					<input type="file" onChange={handleChange} />
					<AttachmentIcon className="widget-media__button-icon" />
					{locale.text(TextId.T_124)}
				</label>
			</div>
			<div className="widget-media__previews">
				{mediaFiles.map((x: MediaFile) => (
					<div key={x.id} className="widget-media__preview">
						<button type="button" onClick={handleRemove(x.id)} className="widget-media__preview-remove-btn">
							x
						</button>

						{imgFileTypes.has(x.file.type) ? (
							<img src={URL.createObjectURL(x.file)} className="widget-media__preview-img" />
						) : (
							<div>{x.file.name}</div>
						)}
					</div>
				))}
			</div>
		</Widget>
	);
});
