import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 64 64" className={className}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-32.000000, -2793.000000)">
					<g transform="translate(33.000000, 2794.000000)">
						<circle stroke="#4C3D5F" strokeWidth="2" cx="30.6644982" cy="30.6644982" r="30.6644982" />
						<path
							d="M32.6140799,38.7952699 L32.6140799,42.9601449 L35.9311431,42.9601449 C36.8115477,42.9601449 37.4759422,43.4638949 37.5103074,44.3186449 C37.4448498,45.1896449 36.7886375,46.2101449 35.9311431,46.2101449 L26.1125051,46.2101449 C25.207554,46.2101449 24.4318816,45.2790199 24.4318816,44.3820199 L24.4318816,44.1788949 C24.4318816,43.2818949 25.207554,42.9601449 26.1125051,42.9601449 L29.3412006,42.9601449 L29.3412006,38.7952699 C24.4318816,38.1842699 18.7861647,34.9050199 18.0104923,28.1287699 C17.8992144,27.1618949 18.6879783,26.7101449 19.6682057,26.7101449 L19.673115,26.7101449 C20.4946077,26.7101449 21.1884582,27.1180199 21.2850081,27.9288949 C22.6088878,39.0325199 39.4347605,38.8293949 40.7570037,27.7257699 C40.8535537,26.9148949 41.5490405,26.7101449 42.3705333,26.7101449 L42.3754426,26.7101449 C43.3556699,26.7101449 44.10025,27.1618949 43.9889721,28.1287699 C43.2132997,34.9050199 39.1598386,38.1842699 32.6140799,38.7952699 L32.6140799,38.7952699 Z M37.5233989,44.3820199 C37.5233989,44.3592699 37.5119439,44.3413949 37.5103074,44.3186449 C37.5135803,44.2731449 37.5233989,44.2243949 37.5233989,44.1788949 L37.5233989,44.3820199 Z M27.7047609,17.7726449 C27.7047609,16.8756449 28.4804333,16.9601449 29.3853845,16.9601449 L32.6582638,16.9601449 C33.5615785,16.9601449 34.2505196,16.8756449 34.2505196,17.7726449 L34.2505196,25.8976449 C34.2505196,27.6916449 32.7842697,29.1476449 30.9776403,29.1476449 C29.1710109,29.1476449 27.7047609,27.6916449 27.7047609,25.8976449 L27.7047609,17.7726449 Z M30.9776403,32.3976449 C34.5925355,32.3976449 37.5233989,29.4872699 37.5233989,25.8976449 L37.5233989,16.1476449 C37.5233989,14.3520199 36.1013329,13.7101449 34.2947035,13.7101449 L27.7489448,13.7101449 C25.940679,13.7101449 24.4318816,14.3520199 24.4318816,16.1476449 L24.4318816,25.8976449 C24.4318816,29.4872699 27.362745,32.3976449 30.9776403,32.3976449 L30.9776403,32.3976449 Z"
							fill="#4C3D5F"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}
