import React from "react";
import { observer } from "mobx-react-lite";
import { XOptionalCategory } from "@external-types/widgets/types";
import { createWidget } from "./";

interface Props {
	widget: XOptionalCategory;
}

export const OptionalCategoryWidget = observer(function({ widget }: Props) {
	return <>{widget.items != null && widget.items.map(item => createWidget(item))}</>;
});
