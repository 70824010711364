import { action } from "mobx";
import { TasksSession } from "./session/tasks-session";

export class AppState {
	public tasks: TasksSession = new TasksSession();

	public clean() {
		this.tasks = new TasksSession();
	}

	@action
	public init() {
		this.clean();
	}
}
