import { App } from "./app";

import { ILocaleStore } from "@app-types/stores";
import { text, TextId } from "@helpers/language";

export class LocaleStore implements ILocaleStore {
	private appStore: App;

	public constructor(appStore: App) {
		this.appStore = appStore;
	}

	public text(id: TextId): string {
		return text(this.appStore.language, id);
	}
}
