import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg className={className} x="0px" y="0px" viewBox="0 0 512 512">
			<g>
				<g>
					<rect x="51.2" y="51.2" width="51.2" height="51.2" />
				</g>
			</g>
			<g>
				<g>
					<path
						d="M358.4,0v25.6h-25.6v25.6h25.6v102.4H384v25.6h-25.6v25.6H384h25.6v-51.2h76.8v25.6H512v-25.6V0H358.4z M486.4,128H384
			V25.6h102.4V128z"
					/>
				</g>
			</g>
			<g>
				<g>
					<rect x="409.6" y="51.2" width="51.2" height="51.2" />
				</g>
			</g>
			<g>
				<g>
					<rect x="51.2" y="409.6" width="51.2" height="51.2" />
				</g>
			</g>
			<g>
				<g>
					<rect x="358.4" y="358.4" width="25.6" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<rect x="230.4" y="486.4" width="25.6" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<rect x="256" y="384" width="25.6" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<rect x="256" y="435.2" width="25.6" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<rect x="486.4" y="307.2" width="25.6" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<rect y="307.2" width="25.6" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<rect y="179.2" width="25.6" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<polygon points="281.6,25.6 281.6,51.2 230.4,51.2 230.4,76.8 307.2,76.8 307.2,51.2 307.2,25.6 		" />
				</g>
			</g>
			<g>
				<g>
					<path
						d="M486.4,204.8v25.6h-51.2V256h-76.8v-25.6h-25.6V256h-51.2v25.6h76.8v25.6h-51.2h-25.6v-25.6H256v25.6h-25.6v-25.6h-25.6
			v25.6h-25.6v25.6h51.2v25.6H256v-25.6h51.2v25.6h-25.6V384h25.6v51.2h25.6v25.6h25.6v-25.6h76.8V384h25.6v-25.6h-25.6v-51.2H384
			v-25.6h51.2v25.6h25.6v-25.6h25.6V256H512v-25.6v-25.6H486.4z M409.6,332.8v76.8h-76.8v-76.8H409.6z"
					/>
				</g>
			</g>
			<g>
				<g>
					<polygon
						points="332.8,153.6 307.2,153.6 307.2,179.2 281.6,179.2 281.6,204.8 281.6,230.4 307.2,230.4 307.2,204.8 332.8,204.8
			332.8,179.2 358.4,179.2 358.4,153.6 		"
					/>
				</g>
			</g>
			<g>
				<g>
					<rect x="281.6" y="486.4" width="51.2" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<rect x="204.8" y="25.6" width="25.6" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<rect x="409.6" y="204.8" width="25.6" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<rect x="435.2" y="179.2" width="25.6" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<rect x="102.4" y="307.2" width="25.6" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<path
						d="M153.6,486.4V384h25.6v-25.6v-25.6h-25.6v25.6H76.8v-25.6H51.2H25.6v25.6H0V512h153.6h51.2v-25.6H153.6z M128,486.4H25.6
			V384H128V486.4z"
					/>
				</g>
			</g>
			<g>
				<g>
					<rect x="153.6" y="153.6" width="25.6" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<path
						d="M179.2,0h-25.6H0v153.6h25.6v25.6h25.6v-25.6h25.6v25.6h25.6v-25.6h51.2v-51.2h25.6V76.8h-25.6V25.6h25.6h25.6V0H179.2z
			 M128,128H25.6V25.6H128V128z"
					/>
				</g>
			</g>
			<g>
				<g>
					<rect x="307.2" y="76.8" width="25.6" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<rect x="256" width="25.6" height="25.6" />
				</g>
			</g>
			<g>
				<g>
					<path
						d="M307.2,153.6V128h-25.6v-25.6H256V128h-51.2v-25.6h-25.6V128v25.6h25.6v51.2H128v-25.6h-25.6v25.6H25.6v25.6h51.2V256H0
			v25.6h76.8v25.6h25.6v-25.6H128v25.6h25.6v-25.6h51.2V256h-25.6v-25.6h25.6h25.6V256H256h25.6v-25.6H256v-25.6h-25.6v-51.2H307.2z
			 M153.6,256h-51.2v-25.6h51.2V256z"
					/>
				</g>
			</g>
			<g>
				<g>
					<polygon
						points="230.4,409.6 230.4,384 230.4,358.4 204.8,358.4 204.8,384 179.2,384 179.2,409.6 204.8,409.6 204.8,435.2
			179.2,435.2 179.2,460.8 230.4,460.8 230.4,435.2 256,435.2 256,409.6 		"
					/>
				</g>
			</g>
			<g>
				<g>
					<polygon
						points="460.8,460.8 460.8,435.2 435.2,435.2 435.2,460.8 358.4,460.8 358.4,486.4 409.6,486.4 409.6,512 435.2,512
			435.2,486.4 486.4,486.4 486.4,512 512,512 512,486.4 512,460.8 		"
					/>
				</g>
			</g>
			<g>
				<g>
					<polygon points="486.4,358.4 486.4,384 460.8,384 460.8,409.6 512,409.6 512,384 512,358.4 		" />
				</g>
			</g>
		</svg>
	);
}
