import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { useParams, withRouter } from "react-router";
import { StoreContext } from "../context";
import { createWidget } from "@components/widgets";
import { WidgetPager } from "@components/controls/widget-pager";
import { Notification } from "@components/controls/notification";
import cn from "classnames";
import { TriangleIcon } from "@icons/index";
import { Paths } from "../routes/paths";
import { Login } from "./login";
import { Overlay } from "@components/controls/overlay";
import { TextId } from "@helpers/language";

export const Task = withRouter(
	observer(function () {
		const { tasksStore, appStore, locale } = useContext(StoreContext);
		const [notificationVisible, updateNotificationVisibility] = React.useState(false);
		const { id } = useParams<{ id: string }>();
		const { task, error } = tasksStore;
		const type = document.location.pathname.includes(Paths.Reports) ? "closedlink" : "openlink";

		React.useEffect(() => {
			if (id) {
				tasksStore.fetchTask(id, type);
			}
		}, [appStore.authUser, id]);

		if (type === "closedlink" && appStore.isAppLoaded && appStore.authUser === null) {
			return <Login />;
		}

		function handleSubmit() {
			const isValid = task!.validate();

			if (!isValid) {
				updateNotificationVisibility(true);
			} else {
				task!.submit();
			}
		}

		function handleClose() {
			updateNotificationVisibility(false);
		}

		if (error) {
			return (
				<>
					{/*<AppHeader />*/}
					<div className="app__content">
						<div style={{ alignSelf: "center" }}>{error}</div>
					</div>
				</>
			);
		}

		if (!task) {
			return (
				<>
					{/*<AppHeader />*/}
					<div className="app__content">{locale.text(TextId.T_109)}</div>
				</>
			);
		}

		if (task.isCompleted) {
			return (
				<>
					{/*<AppHeader />*/}
					<div className="app__content">
						<div
							style={{
								alignSelf: "center",
								alignItems: "center",
								display: "flex",
								flexDirection: "column",
							}}
						>
							{locale.text(TextId.T_107)}!
						</div>
					</div>
				</>
			);
		}

		return (
			<>
				{/*<AppHeader />*/}
				<div className="app__content">
					<div className="task-page">
						{task.hasLogo && (
							<div className="task-page-logo">
								<img src={`/api/openlink/${id}/logo`} />
							</div>
						)}

						<div className="task-page__header">
							<div className="task-page__header-content">{task.name}</div>
							<WidgetPager
								hasNext={task.hasNext}
								hasPrev={task.hasPrev}
								pageName={task.activePage.caption}
								pages={task.pages.map((page, index) => ({ id: index, name: page.caption }))}
								onNext={task.next}
								onPrev={task.prev}
								goto={task.goto}
							/>
						</div>
						{task.activePage.items.map((item) => createWidget(item))}

						<div className="task-page__actions">
							<div
								onClick={task.prev}
								className={cn("widget-pager__button", "widget-pager__button--prev", {
									"widget-pager__button--hidden": !task.hasPrev,
								})}
							>
								<TriangleIcon />
							</div>
							<button
								type="submit"
								className="button button--primary button--submit"
								onClick={handleSubmit}
								disabled={task.inProgress}
							>
								{locale.text(TextId.T_108)}
							</button>
							<div
								onClick={task.next}
								className={cn("widget-pager__button", "widget-pager__button--next", {
									"widget-pager__button--hidden": !task.hasNext,
								})}
							>
								<TriangleIcon />
							</div>
						</div>
					</div>
					{notificationVisible && <Notification text={locale.text(TextId.T_113)} onClose={handleClose} />}
				</div>
				{task.inProgress && <Overlay global />}
			</>
		);
	}),
);
