import React from "react";
import { CategoryWidget } from "./category";

import {
	XCategory,
	XCheckBox,
	XEmoji,
	XMoney,
	XPhoto,
	XQuestion,
	XRichEdit,
	XSignature,
	XSlider,
	XTextArea,
	XWidget,
} from "@external-types/widgets/types";
import { XWidgetName } from "@external-types/widgets/widget-name";
import { TextAreaWidget } from "@components/widgets/textarea";
import { QuestionWidget } from "@components/widgets/question";
import { SignatureWidget } from "@components/widgets/signature";
import { SliderWidget } from "@components/widgets/slider";
import { PhotoWidget } from "@components/widgets/photo";
import { CheckBoxWidget } from "@components/widgets/checkbox";
import { RichEditWidget } from "@components/widgets/richedit";
import { MoneyWidget } from "@components/widgets/money";
import { RatesWidget } from "@components/widgets/rates";

export function createWidget(widgetRecord: Record<XWidgetName, XWidget>) {
	const widgetName = Object.keys(widgetRecord)[0] as XWidgetName;
	const widget = widgetRecord[widgetName];

	switch (widgetName) {
		case XWidgetName.Category:
			return <CategoryWidget widget={widget as XCategory} key={widget.id} />;
		case XWidgetName.TextArea:
			return <TextAreaWidget widget={widget as XTextArea} key={widget.id} />;
		case XWidgetName.Question:
			return <QuestionWidget widget={widget as XQuestion} key={widget.id} />;
		case XWidgetName.Signature:
			return <SignatureWidget widget={widget as XSignature} key={widget.id} />;
		case XWidgetName.Slider:
			return <SliderWidget widget={widget as XSlider} key={widget.id} />;
		case XWidgetName.Photo:
			return <PhotoWidget widget={widget as XPhoto} key={widget.id} />;
		case XWidgetName.CheckBox:
			return <CheckBoxWidget widget={widget as XCheckBox} key={widget.id} />;
		case XWidgetName.RichEdit:
			return <RichEditWidget widget={widget as XRichEdit} key={widget.id} />;
		case XWidgetName.Money:
			return <MoneyWidget widget={widget as XMoney} key={widget.id}/>;
		case XWidgetName.Rates:
			return <RatesWidget widget={widget as XEmoji} key={widget.id} />
		default:
			return (
				<div
					style={{ color: "red" }}
					key={Object.values(widgetRecord)[0].id}
				>{`Not supported ${widgetName}`}</div>
			);
	}
}
