import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import cn from "classnames";

import { StoreContext } from "../../context";
import { RequiredAsterisk } from "@components/controls/required-asterisk";
import { XWidget } from "@external-types/widgets/types";

interface Props {
	widget: XWidget;
	isRequired: boolean;
	caption: React.ReactNode;
	children: React.ReactNode;
	className?: string;
}

export const Widget = observer(function ({ widget, isRequired, caption, className, children }: Props) {
	const { tasksStore } = useContext(StoreContext);
	const { invalidQuestionIds } = tasksStore.task!;

	return (
		<div className={cn("widget", className, { "widget--invalid": invalidQuestionIds.has(widget.id) })}>
			<div className="widget-label">
				{caption}
				{isRequired && <RequiredAsterisk />}
			</div>
			{children}
		</div>
	);
});
