import { TextId } from "./text-id";

export const Texts = {
	[TextId.T_101]: "Аудіо",
	[TextId.T_102]: "Ваше ім'я",
	[TextId.T_103]: "Введіть пароль",
	[TextId.T_104]: "Повернутися на головну сторінку",
	[TextId.T_105]: "Відео",
	[TextId.T_106]: "Вхід",
	[TextId.T_107]: "Готово",
	[TextId.T_108]: "Завершити",
	[TextId.T_109]: "Завантаження",
	[TextId.T_110]: "Завдання",
	[TextId.T_111]: "Змінити значення",
	[TextId.T_112]: "Логін",
	[TextId.T_113]: "Не всі обов'язкові поля заповнюються",
	[TextId.T_114]: "Невірний логін або пароль.",
	[TextId.T_115]: "Опитування недоступне. Ви повинні увійти на основний сайт.",
	[TextId.T_116]: "Опитування недоступне. Будь ласка, зв'яжіться з власником.",
	[TextId.T_117]: "Відміна",
	[TextId.T_118]: "Очистити",
	[TextId.T_119]: "Помилка камери",
	[TextId.T_120]: "Пароль",
	[TextId.T_121]: "План",
	[TextId.T_122]: "Сталася невідома помилка",
	[TextId.T_123]: "Ця сторінка не існує",
	[TextId.T_124]: "Файл",
	[TextId.T_125]: "Факт",
	[TextId.T_126]: "Фото",
};
