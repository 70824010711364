import React, { useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Routes } from "../routes";
import { Overlay } from "./controls/overlay";
import { StoreContext } from "../context";

export const App = observer(function() {
	const { appStore } = useContext(StoreContext);

	return appStore.isAppLoaded ? (
		<BrowserRouter>
			<Routes />
		</BrowserRouter>
	) : (
		<Overlay />
	);
});

// import React from "react";
//
// export function App() {
// 	const ret = "http://192.168.1.74:8081/?id={CODE}";
//
// 	return (
// 		<div>
// 			MOJO app
// 			<input type="file" accept="image/*" />
// 			<input type="text" />
// 			<a href={`http://zxing.appspot.com/scan?ret=${encodeURIComponent(ret)}&SCAN_FORMATS=UPC_A,EAN_13,CODE_39`}>
// 				zxing
// 			</a>
// 		</div>
// 	);
// }
