import { TextId } from "./text-id";

export const Texts = {
	[TextId.T_101]: "Audio",
	[TextId.T_102]: "Tu nombre",
	[TextId.T_103]: "Introducir la contraseña",
	[TextId.T_104]: "Volver a la página principal",
	[TextId.T_105]: "Video",
	[TextId.T_106]: "Ingresar",
	[TextId.T_107]: "Hecho",
	[TextId.T_108]: "Completar",
	[TextId.T_109]: "Cargando",
	[TextId.T_110]: "Tareas",
	[TextId.T_111]: "Cambiar el valor",
	[TextId.T_112]: "Ingresar",
	[TextId.T_113]: "No se rellenan todos los campos obligatorios",
	[TextId.T_114]: "Usuario o contraseña incorrecta.",
	[TextId.T_115]: "La encuesta no está disponible. Debe iniciar sesión en el sitio principal.",
	[TextId.T_116]: "La encuesta no está disponible. Póngase en contacto con el propietario.",
	[TextId.T_117]: "Cancelar",
	[TextId.T_118]: "Borrar",
	[TextId.T_119]: "Error de cámara",
	[TextId.T_120]: "Contraseña",
	[TextId.T_121]: "Plan",
	[TextId.T_122]: "Error desconocido",
	[TextId.T_123]: "Esta página no existe",
	[TextId.T_124]: "Archivo",
	[TextId.T_125]: "Hecho",
	[TextId.T_126]: "Una fotografía",
};
