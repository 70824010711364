import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import CanvasDraw from "react-canvas-draw";
import { XSignature } from "@external-types/widgets/types";
import { StoreContext } from "../../context";
import { XWidgetName } from "@external-types/widgets/widget-name";
import { Widget } from "@components/widgets/widget";
import { TextId } from "@helpers/language";

interface Props {
	widget: XSignature;
}

export const SignatureEmptyValue = JSON.stringify({ lines: [] });

export const SignatureWidget = observer(function ({ widget }: Props) {
	const canvas = React.useRef<CanvasDraw>(null);
	const { tasksStore } = useContext(StoreContext);
	const answer = tasksStore.task!.getFormValue(widget.id);
	const value = answer ? answer.value : SignatureEmptyValue;
	const wrapperRef = React.useRef<HTMLDivElement>(null);
	const [width, setWidth] = React.useState(0);
	const { locale } = useContext(StoreContext);

	React.useEffect(() => {
		canvas.current!.loadSaveData(value, true);
	}, [value]);

	React.useEffect(() => {
		const { width } = wrapperRef.current!.getBoundingClientRect();

		setWidth(width);
	});

	function handleClear() {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value: SignatureEmptyValue,
			type: XWidgetName.Signature,
		});
	}

	function handleChange() {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value: canvas.current!.getSaveData(),
			type: XWidgetName.Signature,
		});
	}

	return (
		<Widget widget={widget} caption={widget.caption} isRequired>
			<div ref={wrapperRef}>
				<CanvasDraw
					ref={canvas}
					brushColor="#000"
					brushRadius={1}
					lazyRadius={1}
					canvasWidth={width || "100%"}
					canvasHeight={200}
					hideGrid
					onChange={handleChange}
				/>
			</div>
			<div className="widget-signature__actions">
				<button className="widget-button" onClick={handleClear}>
					{locale.text(TextId.T_118)}
				</button>
			</div>
		</Widget>
	);
});
