import axios from "axios";
import { Credentials, XUploadFileRef } from "@app-types/models";
import { XUserInfo } from "@external-types/user/user-info";
import { XTaskInfo } from "@external-types/task";
import { XLinkExecutionInfo } from "@external-types/task/link-execution-info";

const TOKEN_KEY = "app_token";

const api = axios.create({ baseURL: "/api" });

export const Proxy = {
	authToken(token?: string | null) {
		if (token) {
			api.defaults.headers.common.AUTHTOKEN = token;
			localStorage.setItem(TOKEN_KEY, token);
		} else {
			delete api.defaults.headers.AUTHTOKEN;
			localStorage.removeItem(TOKEN_KEY);
		}
	},
};

Proxy.authToken(localStorage.getItem(TOKEN_KEY)!);

const actions = {
	login(credentials: Credentials): Promise<XUserInfo> {
		return api.post("/user/login", credentials).then(response => response.data);
	},

	logout() {
		return api.post("/user/logout");
	},

	profile(): Promise<XUserInfo> {
		return api.get("/user").then(response => response.data);
	},

	fetchActiveTasks(): Promise<XTaskInfo[]> {
		return api.get("/tasks/active").then(response => response.data);
	},

	fetchArchivedTasks(): Promise<XTaskInfo[]> {
		return api.get("/tasks/archive").then(response => response.data);
	},

	fetchTask(taskId: string, type: string): Promise<XLinkExecutionInfo> {
		return api.get(`/${type}/${taskId}`).then(response => response.data);
	},

	completeTask(taskId: string, payload: any, type: string): Promise<any> {
		return api.post(`/${type}/${taskId}/complete`, payload).then(response => response.data);
	},

	addErrorFilter(filter: (error: any) => void) {
		api.interceptors.response.use(resp => resp, error => filter(error));
	},

	uploadMedia(uuid: string, file: File, type: string): Promise<XUploadFileRef> {
		const formData = new FormData();
		formData.append("file", new Blob([file], { type: file.type }), file.name || "file");

		return api.post(`/${type}/${uuid}/upload`, formData).then(response => response.data);
	},
};

export { actions };
