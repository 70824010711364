import { IAppStore, ITasksStore } from "@app-types/stores";
import { actions } from "@actions";
import { AppState } from "./app-state";
import { runInAction } from "mobx";
import { OpenLinkTask } from "./models/open-link-task";
import { text, TextId } from "@helpers/language";

export class TasksStore implements ITasksStore {
	public constructor(private readonly appStore: IAppStore, private readonly appState: AppState) {}

	protected get session() {
		return this.appState.tasks;
	}

	public get tasks() {
		return this.session.items;
	}

	public get task() {
		return this.session.task;
	}

	public get error() {
		return this.session.error;
	}

	public async fetch() {
		// const tasks = await actions.fetchActiveTasks();
		//
		// tasks.forEach(t => actions.fetchTask(t.id));
		//
		// runInAction(() => {
		// 	this.session.items = tasks;
		// });
	}

	public async fetchTask(taskId: string, type: string) {
		try {
			runInAction(() => {
				this.session.task = null;
			});

			const task = await actions.fetchTask(taskId, type);

			runInAction(() => {
				this.session.task = new OpenLinkTask(task);
			});
		} catch (error) {
			runInAction(() => {
				// @ts-ignore
				const status = error.response.status;

				if (status === 401) {
					this.session.error = text(this.appStore.language, TextId.T_115);
				} else if (status === 404) {
					this.session.error = text(this.appStore.language, TextId.T_116);
				}
			});
		}
	}
}
