import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { XMoney } from "@external-types/widgets/types";
import { StoreContext } from "../../context";
import { Widget } from "@components/widgets/widget";
import { XWidgetName } from "@external-types/widgets/widget-name";
import { TextId } from "@helpers/language";

interface Props {
	widget: XMoney;
}

export const MoneyWidget = observer(function ({ widget }: Props) {
	const { tasksStore, locale } = useContext(StoreContext);

	const answer = tasksStore.task!.getFormValue(widget.id);
	const value = answer ? answer.value : {};

	function handlePlanChange(e: React.FormEvent<HTMLInputElement>) {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value: {
				...(answer ? answer.value : {}),
				plan: e.currentTarget.value,
			},
			type: XWidgetName.Money,
		});
	}

	function handleFactChange(e: React.FormEvent<HTMLInputElement>) {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value: {
				...(answer ? answer.value : {}),
				fact: e.currentTarget.value,
			},
			type: XWidgetName.Money,
		});
	}

	return (
		<Widget widget={widget} caption={widget.caption} isRequired={true}>
			<div className="widget-money">
				{!widget.hidePlan && (
					<label>
						{locale.text(TextId.T_121)}
						<input
							type="text"
							onChange={handlePlanChange}
							value={widget.plan || value.plan || ""}
							disabled={widget.plan != null}
						/>
					</label>
				)}

				<label>
					{locale.text(TextId.T_125)}
					<input type="text" onChange={handleFactChange} value={value.fact || ""} />
				</label>
			</div>
		</Widget>
	);
});
