import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 64 64" className={className}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-32.000000, -2884.000000)" stroke="#4C3D5F">
					<g transform="translate(33.000000, 2885.000000)">
						<g>
							<path
								d="M39,19.5221489 L39,38.6492797 C39,42.7089704 35.6430464,46 31.5,46 L31.5,46 C27.3578644,46 24,42.7102238 24,38.6492797 L24,19.5221489 L24,19.5221489"
								strokeWidth="3"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M34.125,20.9962733 L34.125,36.8302541 C34.125,38.1340085 33.0339901,39.1909103 31.6875,39.1909103 C30.3413059,39.1909103 29.25,38.134411 29.25,36.8302541 L29.25,30.6876478 L29.25,23.5567771"
								strokeWidth="3"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M34.0965919,27.4672675 C34.0965919,30.1674677 31.8370058,32.3564126 29.0482959,32.3564126 C26.2601991,32.3564126 24,30.1683014 24,27.4672675 L24,14.7453416"
								strokeWidth="3"
								strokeLinecap="round"
								strokeLinejoin="round"
								transform="translate(29.048296, 23.550877) rotate(-180.000000) translate(-29.048296, -23.550877) "
							/>
							<circle strokeWidth="2" cx="30.6644982" cy="30.6644982" r="30.6644982" />
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
