import React from "react";
import { Loader } from "./loader";
import { bem } from "@helpers/bem";

interface Props {
	inline?: boolean;
	global?: boolean;
	showLoader?: boolean;
}

export function Overlay({ inline = false, global = false, showLoader = true }: Props) {
	const cnb = bem("overlay", { inline, global });
	return <div className={cnb.classNames()}>{showLoader && <Loader className={cnb.em("loader")} />}</div>;
}
