export enum XWidgetName {
	Category = "category",
	Page = "page",
	LineEdit = "lineedit",
	Select = "select",
	CheckBox = "checkbox",
	TextArea = "textarea",
	Slider = "slider",
	Money = "money",
	FileUpload = "fileupload",
	ButtonSelect = "buttonselect",
	Photo = "photo",
	Signature = "signature",
	RichEdit = "richedit",
	Question = "question",
	Optional = "optional",
	Rates = "emoji",
}
