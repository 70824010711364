import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import cn from "classnames";
import { XQuestion } from "@external-types/widgets/types";

import { OptionalCategoryWidget } from "@components/widgets/optional-category";
import { StoreContext } from "../../context";
import { XWidgetName } from "@external-types/widgets/widget-name";
import { Widget } from "@components/widgets/widget";

interface Props {
	widget: XQuestion;
}

export const QuestionWidget = observer(function({ widget }: Props) {
	const { tasksStore } = useContext(StoreContext);
	const answer = tasksStore.task!.getFormValue(widget.id);
	const selection: string[] = answer ? answer.value : [];

	function handleSelect(id: string) {
		return function() {
			if (selection.includes(id)) {
				setSelection(selection.filter(s => s !== id));
				return;
			}

			if (widget.answers_count === -1 || selection.length < widget.answers_count) {
				setSelection(selection.concat([id]));
			} else {
				setSelection(selection.slice(0, selection.length - 1).concat([id]));
			}
		};
	}

	function setSelection(value: string[]) {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value,
			type: XWidgetName.Question,
		});
	}

	return (
		<Widget widget={widget} caption={widget.caption} isRequired>
			<div className="widget-question__options">
				{widget.options.map(item => {
					const selected = selection.includes(item.id);
					const style = selected ? { backgroundColor: item.color } : {};

					return (
						<div
							className={cn("widget-question__option", { "widget-question__option--selected": selected })}
							style={style}
							key={item.id}
							onClick={handleSelect(item.id)}
						>
							{item.caption}
						</div>
					);
				})}
			</div>

			{widget.optionals
				.filter(({ optional }) => selection.some(s => optional.keys.includes(s)))
				.map(({ optional }) => (
					<OptionalCategoryWidget key={optional.id} widget={optional} />
				))}
		</Widget>
	);
});
