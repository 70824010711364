import React from "react";
import onClickOutside from "react-onclickoutside";

interface Props {
	text: string;
	onClose(): void;
}

class NotificationComponent extends React.Component<Props> {
	public handleClickOutside = () => {
		this.props.onClose();
	};

	public render() {
		const { text } = this.props;

		return <div className="notification">{text}</div>;
	}
}

export const Notification = onClickOutside(NotificationComponent);
