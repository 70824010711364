import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 64 64" version="1.1" className={className}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-32.000000, -2628.000000)">
					<g transform="translate(33.000000, 2629.000000)">
						<circle stroke="#4C3D5F" strokeWidth="2" cx="30.6644982" cy="30.6644982" r="30.6644982" />
						<path
							d="M31,28.25 C27.6865,28.25 25,30.9365 25,34.25 C25,37.5635 27.6865,40.25 31,40.25 C34.3135,40.25 37,37.5635 37,34.25 C37,30.9365 34.3135,28.25 31,28.25 L31,28.25 Z M26.5,23 L26.5,21.5 C26.5,20.672 27.172,20 28,20 L34,20 C34.828,20 35.5,20.672 35.5,21.5 L35.5,23 L26.5,23 Z M35.5,17 L26.5,17 C24.8425,17 23.5,18.3425 23.5,20 L23.5,23 L19,23 C17.3425,23 16,24.3425 16,26 L16,38 C16,39.6575 17.3425,41 19,41 L20.5,41 C21.328,41 22,40.328 22,39.5 C22,38.672 21.328,38 20.5,38 C19.672,38 19,37.328 19,36.5 L19,27.5 C19,26.672 19.672,26 20.5,26 L41.5,26 C42.328,26 43,26.672 43,27.5 L43,36.5 C43,37.328 42.328,38 41.5,38 C40.672,38 40,38.672 40,39.5 C40,40.328 40.672,41 41.5,41 L43,41 C44.6575,41 46,39.6575 46,38 L46,23 L44.5,23 L44.5,21.5 C44.5,20.672 43.828,20 43,20 C42.172,20 41.5,20.672 41.5,21.5 L41.5,23 L38.5,23 L38.5,20 C38.5,18.3425 37.1575,17 35.5,17 L35.5,17 Z M31,31.25 C32.6545,31.25 34,32.5955 34,34.25 C34,35.9045 32.6545,37.25 31,37.25 C29.3455,37.25 28,35.9045 28,34.25 C28,32.5955 29.3455,31.25 31,31.25 L31,31.25 Z"
							fill="#4C3D5F"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}
