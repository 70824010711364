import React from "react";
import { observer } from "mobx-react-lite";
import cn from "classnames";
import { DropdownMenu } from "@components/controls/dropdown-menu";
import { TriangleIcon } from "@icons/index";

interface Props {
	pages: any[];
	pageName: string;
	hasNext: boolean;
	hasPrev: boolean;
	onNext(): void;
	onPrev(): void;
	goto(index: number): void;
}

export const WidgetPager = observer(function({ pages, pageName, hasNext, hasPrev, onNext, onPrev, goto }: Props) {
	return (
		<div className="widget-pager">
			<div
				onClick={onPrev}
				className={cn("widget-pager__button", "widget-pager__button--prev", {
					"widget-pager__button--hidden": !hasPrev,
				})}
			>
				<TriangleIcon />
			</div>
			<div className="widget-pager__name">
				<DropdownMenu items={pages} onClick={goto}>
					{pageName}
				</DropdownMenu>
			</div>
			<div
				onClick={onNext}
				className={cn("widget-pager__button", "widget-pager__button--next", {
					"widget-pager__button--hidden": !hasNext,
				})}
			>
				<TriangleIcon />
			</div>
		</div>
	);
});
