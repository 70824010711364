import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 64 64" version="1.1" className={className}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-32.000000, -2706.000000)">
					<g transform="translate(33.000000, 2707.000000)">
						<circle stroke="#4C3D5F" strokeWidth="2" cx="30.6644982" cy="30.6644982" r="30.6644982" />
						<path
							d="M42,33.073527 L42,33.1477088 L42,36.7594361 L38.6505,34.6050725 L42,32.7551634 L42,33.073527 Z M33,38.4687088 C33,39.3217997 32.328,40.0141634 31.5,40.0141634 L19.5,40.0141634 C18.672,40.0141634 18,39.3217997 18,38.4687088 L18,32.2868906 C18,31.4337997 18.672,30.7414361 19.5,30.7414361 L31.5,30.7414361 C32.328,30.7414361 33,31.4337997 33,32.2868906 L33,38.4687088 Z M21.165,23.7868906 C21.993,23.7868906 22.665,24.4807997 22.665,25.3323452 C22.665,26.1838906 21.993,26.8777997 21.165,26.8777997 C20.3385,26.8777997 19.665,26.1838906 19.665,25.3323452 C19.665,24.4807997 20.3385,23.7868906 21.165,23.7868906 L21.165,23.7868906 Z M28.665,20.6959816 C30.3195,20.6959816 31.665,22.0822543 31.665,23.7868906 C31.665,25.491527 30.3195,26.8777997 28.665,26.8777997 C27.012,26.8777997 25.665,25.491527 25.665,23.7868906 C25.665,22.0822543 27.012,20.6959816 28.665,20.6959816 L28.665,20.6959816 Z M42.705,28.3537088 L36,32.665527 L36,29.9687088 C36,28.4572543 35.07,27.2023452 33.678,26.9349816 C34.2135,26.0107997 34.6035,24.9413452 34.6035,23.7868906 C34.6035,20.3729816 31.9485,17.6050725 28.635,17.6050725 C26.2095,17.6050725 24.1485,19.0948906 23.202,21.2276179 C22.584,20.8999816 21.9015,20.6959816 21.1575,20.6959816 C18.672,20.6959816 16.662,22.771527 16.662,25.3323452 C16.662,25.9783452 16.668,26.5934361 16.9005,27.1513452 C15.855,27.6350725 15,28.7107088 15,29.9687088 L15,39.2414361 C15,40.9476179 16.593,43.1050725 18.2505,43.1050725 L33.2505,43.1050725 C34.9065,43.1050725 36,40.9476179 36,39.2414361 L36,36.5446179 L42.705,40.8564361 C43.7055,41.4993452 45,40.7590725 45,39.5458906 L45,29.6642543 C45,28.4510725 43.7055,27.7107997 42.705,28.3537088 L42.705,28.3537088 Z"
							fill="#4C3D5F"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}
