import React from "react";
import { render } from "react-dom";
import { configure } from "mobx";

import { App } from "@components/app";
import { StoreContext } from "./context";
import { createStores } from "./stores/create-stores";

configure({ enforceActions: "observed" });

const stores = createStores();

render(
	<StoreContext.Provider value={stores}>
		<App />
	</StoreContext.Provider>,
	document.getElementById("app"),
);
