import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { XCheckBox } from "@external-types/widgets/types";
import { StoreContext } from "../../context";
import { XWidgetName } from "@external-types/widgets/widget-name";

interface Props {
	widget: XCheckBox;
}

export const CheckBoxWidget = observer(function({ widget }: Props) {
	const { tasksStore } = useContext(StoreContext);
	const answer = tasksStore.task!.getFormValue(widget.id);
	const value = answer ? answer.value : "";

	function handleChange(e: React.FormEvent<HTMLInputElement>) {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value: e.currentTarget.checked ? "true" : "false",
			type: XWidgetName.CheckBox,
		});
	}

	return (
		<div className="widget">
			<label className="checkbox">
				<input type="checkbox" checked={value === "true"} onChange={handleChange} />
				<span>{widget.caption}</span>
			</label>
		</div>
	);
});
